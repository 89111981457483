"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";

import { updateClientMetaTag } from "../../utils/updateClientMetaTag";

const IndexClientMetaData = ({ isEconomy }: { isEconomy: boolean }) => {
  const pathName = usePathname();
  useEffect(() => {
    const updateIndexMetaTags = () => {
      const title = isEconomy
        ? "Omni Ekonomi – för den nya tidens affärsmänniska."
        : "Omni – Alla nyheter. Alla perspektiv.";
      const siteName = isEconomy ? "Omni Ekonomi" : "Omni";
      const siteUrl = isEconomy ? "https://omniekonomi.se" : "https://omni.se";
      const description = isEconomy
        ? "Omni Ekonomi samlar alla världens affärsmedier på ett ställe. Skapa din egen nyhetsmix, bevaka ämnen och få fler insikter från ledande källor."
        : "Omni samlar alla nyheter och perspektiv på ett ställe – gratis. Skapa din egen nyhetsmix, bevaka ämnen och få fler insikter från ledande källor.";
      const siteLogo = isEconomy
        ? "/images/logo-ekonomi.png"
        : "/images/logo-core.png";

      // Update the title
      document.title = title;

      // Update meta tags
      updateClientMetaTag("description", description);

      updateClientMetaTag("og:title", title, "property");
      updateClientMetaTag("og:description", description, "property");
      updateClientMetaTag("og:url", siteUrl, "property");
      updateClientMetaTag("og:site_name", siteName, "property");
      updateClientMetaTag("og:type", "website", "property");
      updateClientMetaTag("og:image", siteLogo, "property");
      updateClientMetaTag("og:locale", "sv_SE", "property");

      updateClientMetaTag("twitter:title", title, "name");
      updateClientMetaTag("twitter:description", description, "name");
      updateClientMetaTag(
        "twitter:site",
        isEconomy ? "@OmniEkonomi" : "@omni_red",
        "name",
      );
      updateClientMetaTag(
        "twitter:creator",
        isEconomy ? "@OmniEkonomi" : "@omni_red",
        "name",
      );
      updateClientMetaTag("twitter:image", siteLogo, "name");

      updateClientMetaTag("canonical", siteUrl, "property");
    };

    const shouldUpdateIndexMetaTags =
      pathName === "/" &&
      document.title !==
        (isEconomy
          ? "Omni Ekonomi – för den nya tidens affärsmänniska."
          : "Omni – Alla nyheter. Alla perspektiv.");
    if (shouldUpdateIndexMetaTags) updateIndexMetaTags();
  }, [isEconomy, pathName]);

  return null;
};

export { IndexClientMetaData };
